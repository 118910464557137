/* Container utama untuk halaman login */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #fffff0;
    font-family: 'Arial', sans-serif;
    padding: 20px;
  }
  
  /* Kartu login */
  .login-card {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;
    width: 100%;
    max-width: 400px;
    text-align: center;
  }
  
  
  /* Judul halaman login */
  .login-title {
    font-size: 1.8rem;
    color: #f44336;
  }
  
  .company-logo-type {
    width: 200px;
    margin-bottom: 20px;
  }

  /* Gaya untuk form login */
  .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Gaya untuk setiap grup form (email & password) */
  .form-group {
    width: 90%;
    margin-bottom: 15px;
    text-align: left;
  }
  
  /* Label untuk input */
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #204080;
  }
  
  /* Input email dan password */
  .form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #204080;
    border-radius: 5px;
    font-size: 1rem;
    box-sizing: border-box;
  }
  
  /* Gaya untuk tombol login */
  .login-button {
    width: 90%;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s;
    background-color: #204080;
  }
  
  /* Hover efek untuk tombol login */
  .login-button:hover {
    background-color: #062c50;
  }
  
  /* Media query untuk responsivitas */
  @media (max-width: 768px) {
    .login-card {
      padding: 20px;
    }
  
    .login-title {
      font-size: 1.5rem;
    }
  
    .form-group input {
      font-size: 0.9rem;
    }
  
    .login-button {
      font-size: 0.9rem;
      padding: 8px;
    }
  }
  