.home-container {
    text-align: center;
    padding: 20px;
}

.button-container {
    display: flex; /* Menggunakan Flexbox */
    justify-content: center; /* Mengatur tombol di tengah */
    gap: 10px; /* Jarak antar tombol */
    flex-wrap: wrap; /* Membungkus tombol jika ruang tidak cukup */
}

.redirect-button, .logout-button {
    padding: 10px 20px; /* Ukuran tombol */
    border: none; /* Menghilangkan border */
    border-radius: 5px; /* Sudut tombol yang membulat */
    cursor: pointer; /* Pointer saat hover */
    transition: background-color 0.3s; /* Efek transisi saat hover */
}

.redirect-button {
    background-color: #007bff; /* Warna latar tombol */
    color: white; /* Warna teks */
}

.redirect-button:hover {
    background-color: #0056b3; /* Warna saat hover */
}

.logout-button {
    background-color: #ff1900; /* Warna latar tombol */
    color: white; /* Warna teks */
}

.logout-button:hover {
    background-color: #c70000; /* Warna saat hover */
}

/* Media query untuk tampilan mobile */
@media (max-width: 600px) {
    .button-container {
        flex-direction: column; /* Mengubah arah tombol menjadi kolom */
        gap: 10px; /* Jarak antar tombol */
    }
}
