/* Container utama */
.employee-detail-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f4f4f9;
    min-height: 100vh;
    font-family: 'Arial', sans-serif;
  }
  
  /* Bagian informasi perusahaan */
  .company-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    background-color: #fffff0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 15px;
    width: 90%;
    max-width: 500px;
  }
  
  .company-logo {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
  }
  
  .company-name {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
  }
  
  .employee-card {
      background-color: #fffff0;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 15px;
      padding-bottom: 54px;
      width: 90%;
      max-width: 500px;
      position: relative; /* Untuk penempatan status */
      overflow: hidden; /* Agar border yang lebih besar tidak meluber */
      background-image: url('../assets/image/background.png'); /* Ganti dengan path gambar Anda */
      background-size: cover; /* Mengatur ukuran gambar agar menutupi seluruh elemen */
      background-position: center; /* Memusatkan gambar */
      background-repeat: no-repeat; /* Menghindari pengulangan gambar */  
  }

  /* Border luar */
  .employee-card:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 20px; /* Tinggi border luar */
      background-color: #094471; /* Warna luar */
      z-index: 1; /* Memastikan border luar di belakang konten */
  }

  /* Border dalam */
  .employee-card:after {
      content: '';
      position: absolute;
      bottom: 24px; /* Mengatur posisi border dalam agar terlihat di atas border luar */
      left: 0;
      right: 0;
      height: 20px; /* Tinggi border dalam */
      background-color: #f44336; /* Warna dalam */
      z-index: 2; /* Memastikan border dalam di depan konten */
  }

  
  /* Foto karyawan tanpa border */
  .employee-photo-container {
    margin-bottom: 0px;
  }
  
  .employee-photo {
    width: 200px;
    height: 200px;
  }
  
  /* Indikator status aktif di pojok kanan atas */
  .employee-status-indicator {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
  }
  
  .active .status-circle {
    background-color: #4caf50; /* Warna hijau untuk status aktif */
  }
  
  .inactive .status-circle {
    background-color: #f44336; /* Warna merah untuk status tidak aktif */
  }
  
  .active .status-text {
    color: #4caf50;
  }
  
  .inactive .status-text {
    color: #f44336; /* Warna merah untuk teks status tidak aktif */
  }
  
  .status-circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 5px;
  }
  
  /* Detail karyawan */
  .employee-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .employee-name {
    font-size: 1.4rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 8px;
  }
  
  .employee-id,
  .employee-division,
  .employee-position {
    font-size: 1rem;
    color: #666;
    margin-bottom: 0px;
  }
  
  /* Gaya untuk tombol Masuk */
  .employee-login-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    color: white;
    background-color: #094471; /* Warna tombol default */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  /* Gaya saat tombol diaktifkan */
  .employee-login-button:hover:enabled {
    background-color: #062c50;
  }

  /* Gaya tombol saat di-disable */
  .employee-login-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  /* Responsiveness */
  @media (max-width: 768px) {
    .employee-detail-container {
      padding: 10px;
      padding-top: 40px;
    }

    .company-logo {
      width: 60px;
      height: 60px;
    }
  
    .company-name {
      font-size: 1.1rem;
      text-align: center;
    }
  
    .employee-photo {
      width: 200px;
      height: 200px;
    }
  
    .employee-name {
      font-size: 1.2rem;
    }
  
    .employee-card {
      padding: 10px;
      padding-bottom: 54px;
    }
  
    .status-text {
      font-size: 0.75rem;
    }
  }
  